import React, {useEffect, useRef, useState} from "react";
import {Button, Col, Form, Row, Tabs} from "antd";
import styled from "@emotion/styled";
import OverviewCard from "components/Molecules/overview-card";
import completedImage from "components/Atoms/images/Completed.png";
import expiredImage from "components/Atoms/images/Expired.png";
import WaitingForReviewIcon from "components/Atoms/images/file-find.png";
import rejectImage from "components/Atoms/images/Rejected.png";
import CusPageHeader from "../../components/Molecules/header";
import Page from "../../components/Molecules/page";
import {useNavigate} from "react-router-dom";
import {useAddProduct, useMerchantsProductsOverview, useMerchantsProductsStatistics,} from "./api";
import ProductTable from "./Home/product-table";
// import JobTable from "./Home/job-table";
import {CreateProductModal} from "./create-product-modal";
import {MainLayout} from "components/Organisms/layouts";
import {IntroductionModal} from "components/Organisms/Introduction";
import {useAuth} from "context/auth-context";
import {useCheckFirstLogin} from "hooks/useCheckFirstLogin";

const { TabPane } = Tabs;

export function Merchant() {
  const { user } = useAuth();
  const { markedFirstLogin } = useCheckFirstLogin(user.id, (user as any).uuid);
  const [showUserGuide, seShowUserGuide] = React.useState(
    user.isFirstLogin === 1
  );

  let navigate = useNavigate();
  const [createProductModalVisible, setCreateProductModalVisible] = useState(
    false
  );
  const [form] = Form.useForm();
  const productTableRef = useRef<any>();

  const [categoryId, setCategoryId] = useState<any>();
  const [mainCategoryId, setMainCategoryId] = useState<any>();
  const [subCategoryId, setSubCategoryId] = useState<any>();
  const [activeTab, setActiveTab] = useState("todo");
  const [createProductSuccess, setCreateProductSuccess] = useState(false);

  const {
    mutate,
    isLoading: isCreating,
    data: createdProduct,
  } = useAddProduct();

  useEffect(() => {
    if (activeTab === "todo") {
      navigate(
        "?status=pending_payment,missing_documents,pending_e_signature,conformity_certificate_expired,rejected"
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    data: overview,
    retry: reloadProductOverview,
  } = useMerchantsProductsOverview();
  const {
    data: statistics,
    retry: reloadStatistics,
  } = useMerchantsProductsStatistics();

  const createProduct = async (values: Record<string, any>) => {
    if (createProductSuccess) {
      await window.open(`/project-page/${createdProduct?.id || ""}`, '_blank');
    } else {
      const newValue = {
        ...values,
        productName: values.productName.toString(),
        productNumber: values.productNumber.toString()
      }

      await mutate(newValue);
      await setCreateProductSuccess(true);
    }
  };

  const handleFormValuesChange = (changedValues: any) => {
    const formFieldName = Object.keys(changedValues)[0];

    if (formFieldName === "mainCategoryId") {
      setMainCategoryId(changedValues[formFieldName]);
      setCategoryId(null);// perform setState here
    }

    if (formFieldName === "categoryId") {
      setCategoryId(changedValues[formFieldName]); // perform setState here
    }

    if (formFieldName === "subcategoryId") {
      setSubCategoryId(changedValues[formFieldName]); // perform setState here
    }
  };

  const handleCloseModal = async () => {
    if (createProductSuccess) {
      // @ts-ignore
      await productTableRef?.current?.reload();
      await reloadProductOverview();
      await reloadStatistics();
    }
    await setCreateProductModalVisible(false);
    await setCreateProductSuccess(false);
    await setCategoryId(null);
    await setSubCategoryId(null);
  };

  const handleTabOnChange = (key: string) => {
    setActiveTab(key);

    if (key === "todo") {
      navigate(
        "?status=pending_payment,missing_documents,pending_e_signature,conformity_certificate_expired,rejected"
      );
    } else {
      navigate("");
    }
  };

  return (
    <>
      <MainLayout haveIntroduction>
        <CusPageHeader
          title={`Home`}
          extra={
            <Button
              type="primary"
              onClick={() => setCreateProductModalVisible(true)}
            >
              Submit Document Review
            </Button>
          }
        />
        <Page>
          <Session>
            <h2>Overview</h2>
            <Row justify="start" gutter={[36, 16]}>
              <Col>
                <OverviewCard
                  icon={WaitingForReviewIcon}
                  title={"Waiting for Review"}
                  total={overview?.waitingForReview}
                  onClick={() => navigate("/home?progress=waiting_for_review")}
                />
              </Col>
              <Col>
                <OverviewCard
                  icon={completedImage}
                  title={"Approved"}
                  total={overview?.completed}
                  onClick={() => navigate("/home?progress=completed")}
                />
              </Col>
              <Col>
                <OverviewCard
                  icon={rejectImage}
                  title={"Rejected"}
                  total={overview?.rejected}
                  onClick={() =>
                    navigate(
                      "/home?progress=rejected_request_additional_test,rejected_quote_accepted,rejected_request_revise,rejected_others"
                    )
                  }
                />
              </Col>
              <Col>
                <OverviewCard
                  icon={expiredImage}
                  title={"Expiring Soon / Expired"}
                  total={overview?.expiredOrExpringSoon}
                  onClick={() => navigate("/home?progress=expiring")}
                />
              </Col>
            </Row>
          </Session>

          <Session>
            <h2>Compliance Management Statistics</h2>
            <Row justify="start" gutter={[36, 16]}>
              <Col>
                <OverviewCard
                  title={"Total Tasks"}
                  total={statistics?.totalProducts}
                  onClick={() => navigate("/home")}
                />
              </Col>
            </Row>
          </Session>

          <Tabs
            defaultActiveKey={"todo"}
            activeKey={activeTab}
            onChange={handleTabOnChange}
          >
            <TabPane tab="To do" key="todo">
              <ProductTable
                ref={productTableRef}
                defaultStatus={[
                  "pending_payment",
                  "missing_documents",
                  // "pending_e_signature",
                  // "conformity_certificate_expired",
                  // "rejected",
                  "rejected_request_additional_test",
                  // "rejected_quote_accepted",
                  "rejected_request_revise",
                  "rejected_others",
                ]}
                hiddenFilterStatus={true}
              />
            </TabPane>
            <TabPane tab="All products" key="all_product">
              <ProductTable ref={productTableRef} />
            </TabPane>
            {/* TODO: Temp hidden this tab
            <TabPane tab="All jobs" key="all_job">
              <JobTable />
            </TabPane> */}
          </Tabs>
        </Page>
        <CreateProductModal
          createProductSuccess={createProductSuccess}
          handleFormValuesChange={handleFormValuesChange}
          isCreating={isCreating}
          createProduct={createProduct}
          createProductModalVisible={createProductModalVisible}
          handleCloseModal={handleCloseModal}
          subCategoryId={subCategoryId}
          mainCategoryId={mainCategoryId}
          form={form}
          categoryId={categoryId}
        />
      </MainLayout>
      <IntroductionModal
        visible={showUserGuide}
        onCancel={() => {
          markedFirstLogin();
          seShowUserGuide(false);
        }}
      ></IntroductionModal>
    </>
  );
}

const Session = styled.div`
  margin-bottom: 28px;
`;
